import managerConst from "./managerConst";

export const manager = {
    data() {
        return {
            managerConst: managerConst,
            startTime: '',
            endTime: '',
            smonth: '',
            selectedPartnerId: null,
        }
    },
    methods:{
        setPartnerId(partnerId){
            this.selectedPartnerId = partnerId
        },
        setStartDate(sdate){
            this.startDate = sdate
        },
        setEndDate(sdate){
            this.endDate = sdate
        },
    },
    created() {

    }
}
