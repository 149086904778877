export default {
    TEAM_IMAG: 'https://assets.b365api.com/images/team/b/',
    ENABLE: 1,
    DISABLE: 0,
    UNCHECKED: 2,
    CHECKED: 1,
    SU_ENABLE: "0",
    SU_DISABLE: "1",

    WAY_HOME: 1,
    WAY_DRAW: 2,
    WAY_AWAY: 3,
    WAY_NONE: -1,


    SPORTS_ID_ALL: 0,
    SPORTS_ID_SOCCER: 1,
    SPORTS_ID_BASEBALL: 2,
    SPORTS_ID_BASKETBALL: 3,
    SPORTS_ID_VOLLEYBALL: 4,
    SPORTS_ID_ICEHOKEY: 5,
    SOCCER_END_TIME: 90,
    TIME_STATUS_MATCH_END: 3,      //실시간 경기 끝남 베팅 정지
    TIME_STATUS_MATCH_INPLAY: 1,      //실시간 경기 진행중
    //축구 풀타임
    ODDS_TYPE_SOCCER_FULLTIME_RESULT: 100,//축구 풀타임 승무패
    ODDS_TYPE_SOCCER_FULLTIME_RESULT_KEY: "ID" + 100,//key
    ODDS_TYPE_SOCCER_FULLTIME_OVER_UNDER_MATCH_GOALS: 101,//축구 풀타임 오버언더 2.5합
    ODDS_TYPE_SOCCER_FULLTIME_OVER_UNDER_MATCH_GOALS_KEY: "ID" + 101,//key
    ODDS_TYPE_SOCCER_FULLTIME_OVER_UNDER_ALTERNATIVE_MATCH_GOALS: 102,//축구 풀타임 오버언더 정확한 합
    ODDS_TYPE_SOCCER_FULLTIME_OVER_UNDER_ALTERNATIVE_MATCH_GOALS_KEY: "ID" + 102,//key
    ODDS_TYPE_SOCCER_FULLTIME_GOALS_ODD_EVEN: 103,          //축구 풀타임 스코어 홀/짝
    ODDS_TYPE_SOCCER_FULLTIME_GOALS_ODD_EVEN_KEY: "ID" + 103, //KEY
    ODDS_TYPE_SOCCER_FULLTIME_ASIAN_HANDICAP: 104,          //축구 풀타임 핸디캡
    ODDS_TYPE_SOCCER_FULLTIME_ASIAN_HANDICAP_KEY: "ID" + 104,//key
    ODDS_TYPE_SOCCER_FULL_TIME_FINAL_SCORE: 105,          //축구 FINAL SCORE
    ODDS_TYPE_SOCCER_FULL_TIME_FINAL_SCORE_KEY: "ID" + 105,//key
    ODDS_TYPE_SOCCER_FULL_TIME_WIN_FAIL_HANDICAP: 106,          //축구 승무패 핸디캡
    ODDS_TYPE_SOCCER_FULL_TIME_WIN_FAIL_HANDICAP_KEY: "ID" + 106,          //3way 핸디캡
    //축구 전반전
    ODDS_TYPE_SOCCER_HALFTIME_RESULT: 150,          //축구 전반전 승무패
    ODDS_TYPE_SOCCER_HALFTIME_RESULT_KEY: "ID" + 150,          //KEY
    ODDS_TYPE_SOCCER_FIRST_HALF_GOALS: 151,          //축구 전반전 오버언더
    ODDS_TYPE_SOCCER_FIRST_HALF_GOALS_KEY: "ID" + 151,          //KEY
    ODDS_TYPE_SOCCER_1ST_HALF_HANDICAP: 152,          //축구 전반전 핸디캡
    ODDS_TYPE_SOCCER_1ST_HALF_HANDICAP_KEY: "ID" + 152,          //KEY
    ODDS_TYPE_SOCCER_BOTH_TEAMS_TO_SCORE_IN_1ST_HALF: 153,          //축구 전반전 양팀모두득점
    ODDS_TYPE_SOCCER_BOTH_TEAMS_TO_SCORE_IN_1ST_HALF_KEY: "ID" + 153,          //KEY
    ODDS_TYPE_SOCCER_HANDICAP_1ST_HALF: 154,          //축구 전반전 핸디캡 3way
    ODDS_TYPE_SOCCER_HANDICAP_1ST_HALF_KEY: "ID" + 154,          //KEY
    //축구 후반전
    ODDS_TYPE_SOCCER_TO_WIN_2AND_HALF: 170,          //축구 후반전 승무패
    ODDS_TYPE_SOCCER_TO_WIN_2AND_HALF_KEY: "ID" + 170,          //KEY
    ODDS_TYPE_SOCCER_BOTH_TEAMS_TO_SCORE_IN_2AND_HALF: 171,          //후반전 양팀모두득점
    ODDS_TYPE_SOCCER_BOTH_TEAMS_TO_SCORE_IN_2AND_HALF_KEY: "ID" + 171,          //KEY


    //야구
    ODDS_TYPE_BASEBALL_FULLTIME_RUN_LINE: 200,                      //야구 RUN LINE
    ODDS_TYPE_BASEBALL_FULLTIME_RUN_LINE_KEY: "ID" + 200,
    ODDS_TYPE_BASEBALL_FULLTIME_TOTAL: 201,                         //야구 TOTAL
    ODDS_TYPE_BASEBALL_FULLTIME_TOTAL_KEY: "ID" + 201,
    ODDS_TYPE_BASEBALL_FULLTIME_MONEY_LINE: 202,                    //야구 MONEY_LINE
    ODDS_TYPE_BASEBALL_FULLTIME_MONEY_LINE_KEY: "ID" + 202,
    ODDS_TYPE_BASEBALL_FULLTIME_ALTERNATIVE_RUN_LINE: 203,          //야구 Alternative Run Line
    ODDS_TYPE_BASEBALL_FULLTIME_ALTERNATIVE_RUN_LINE_KEY: "ID" + 203,
    ODDS_TYPE_BASEBALL_FULLTIME_ALTERNATIVE_GAME_TOTAL: 204,        //야구 Alternative Game Total
    ODDS_TYPE_BASEBALL_FULLTIME_ALTERNATIVE_GAME_TOTAL_KEY: "ID" + 204,
    ODDS_TYPE_BASEBALL_FULLTIME_TOTAL_HITS: 205,                    //야구 Total Hits 안타총개수
    ODDS_TYPE_BASEBALL_FULLTIME_TOTAL_HITS_KEY: "ID" + 205,
    ODDS_TYPE_BASEBALL_FULLTIME_MATCH_CORRECT_SCORE: 206,           //야구 Match Correct Score 정확한 득점수
    ODDS_TYPE_BASEBALL_FULLTIME_MATCH_CORRECT_SCORE_KEY: "ID" + 206,
    ODDS_TYPE_BASEBALL_FULLTIME_WINFAIL_HANDICAP: 207, //3way핸디캡
    ODDS_TYPE_BASEBALL_FULLTIME_WINFAIL_HANDICAP_KEY: "ID" + 207, //3way핸디캡

    //야구 이닝 별 핸디,오버언더,승패
    ODDS_TYPE_BASEBALL_INNINGS_RUN_LINES_3: 223,             //야구  1-3 이닝 핸디캡
    ODDS_TYPE_BASEBALL_INNINGS_RUN_LINES_3_KEY: "ID" + 223,
    ODDS_TYPE_BASEBALL_INNINGS_TOTAL_3: 233,                 //야구 1-3 이닝 오더번더
    ODDS_TYPE_BASEBALL_INNINGS_TOTAL_3_KEY: "ID" + 233,
    ODDS_TYPE_BASEBALL_INNINGS_MONEY_LINES_3: 243,           //야구 1-3 승패
    ODDS_TYPE_BASEBALL_INNINGS_MONEY_LINES_3_KEY: "ID" + 243,

    ODDS_TYPE_BASEBALL_INNINGS_RUN_LINES_5: 225,             //야구 1-5 이닝 핸디캡
    ODDS_TYPE_BASEBALL_INNINGS_RUN_LINES_5_KEY: "ID" + 225,
    ODDS_TYPE_BASEBALL_INNINGS_TOTAL_5: 235,                 //야구 1-5 이닝 오더번더
    ODDS_TYPE_BASEBALL_INNINGS_TOTAL_5_KEY: "ID" + 235,
    ODDS_TYPE_BASEBALL_INNINGS_MONEY_LINES_5: 245,           //야구 1-5 승패
    ODDS_TYPE_BASEBALL_INNINGS_MONEY_LINES_5_KEY: "ID" + 245,

    ODDS_TYPE_BASEBALL_INNINGS_RUN_LINES_7: 227,             //야구 1-7 이닝 핸디캡
    ODDS_TYPE_BASEBALL_INNINGS_RUN_LINES_7_KEY: "ID" + 227,
    ODDS_TYPE_BASEBALL_INNINGS_TOTAL_7: 237,                 //야구 1-7 이닝 오더번더
    ODDS_TYPE_BASEBALL_INNINGS_TOTAL_7_KEY: "ID" + 237,
    ODDS_TYPE_BASEBALL_INNINGS_MONEY_LINES_7: 247,           //야구 1-7 승패
    ODDS_TYPE_BASEBALL_INNINGS_MONEY_LINES_7_KEY: "ID" + 247,

    //innings ALTERNATIVE_GAME_TOTAL
    ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_TOTAL_3: 253,          //야구 1-3이닝 정확안 오버언더
    ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_TOTAL_3_KEY: "ID" + 253,
    ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_TOTAL_5: 255,          //야구 1-5이닝 정확안 오버언더
    ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_TOTAL_5_KEY: "ID" + 255,
    ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_TOTAL_7: 257,          //야구 1-7이닝 정확안 오버언더
    ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_TOTAL_7_KEY: "ID" + 257,

    //innings ALTERNATIVE_GAME_RUN_LINES
    ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_RUN_LINES_3: 263,          //야구 1-3이닝 정확안 핸디
    ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_RUN_LINES_3_KEY: "ID" + 263,
    ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_RUN_LINES_5: 265,          //야구 1-5이닝 정확안 핸디
    ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_RUN_LINES_5_KEY: "ID" + 265,
    ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_RUN_LINES_7: 267,          //야구 1-7이닝 정확안 핸디
    ODDS_TYPE_BASEBALL_INNINGS_ALTERNATIVE_GAME_RUN_LINES_7_KEY: "ID" + 267,

    //야구 이닝별 winner,most hit, total hit
    ODDS_TYPE_BASEBALL_INNING_WINNER_1N: 271,
    ODDS_TYPE_BASEBALL_INNING_WINNER_1N_KEY: "ID" + 271,
    ODDS_TYPE_BASEBALL_INNING_WINNER_2N: 272,
    ODDS_TYPE_BASEBALL_INNING_WINNER_2N_KEY: "ID" + 272,
    ODDS_TYPE_BASEBALL_INNING_WINNER_3N: 273,
    ODDS_TYPE_BASEBALL_INNING_WINNER_3N_KEY: "ID" + 273,
    ODDS_TYPE_BASEBALL_INNING_WINNER_4N: 274,
    ODDS_TYPE_BASEBALL_INNING_WINNER_4N_KEY: "ID" + 274,
    ODDS_TYPE_BASEBALL_INNING_WINNER_5N: 275,
    ODDS_TYPE_BASEBALL_INNING_WINNER_5N_KEY: "ID" + 275,
    ODDS_TYPE_BASEBALL_INNING_WINNER_6N: 276,
    ODDS_TYPE_BASEBALL_INNING_WINNER_6N_KEY: "ID" + 276,
    ODDS_TYPE_BASEBALL_INNING_WINNER_7N: 277,
    ODDS_TYPE_BASEBALL_INNING_WINNER_7N_KEY: "ID" + 277,
    ODDS_TYPE_BASEBALL_INNING_WINNER_8N: 278,
    ODDS_TYPE_BASEBALL_INNING_WINNER_8N_KEY: "ID" + 278,

    //야구 이닝별 most hit
    ODDS_TYPE_BASEBALL_INNING_MOST_HIT_1N: 281,
    ODDS_TYPE_BASEBALL_INNING_MOST_HIT_1N_KEY: "ID" + 281,
    ODDS_TYPE_BASEBALL_INNING_MOST_HIT_2N: 282,
    ODDS_TYPE_BASEBALL_INNING_MOST_HIT_2N_KEY: "ID" + 282,
    ODDS_TYPE_BASEBALL_INNING_MOST_HIT_3N: 283,
    ODDS_TYPE_BASEBALL_INNING_MOST_HIT_3N_KEY: "ID" + 283,
    ODDS_TYPE_BASEBALL_INNING_MOST_HIT_4N: 284,
    ODDS_TYPE_BASEBALL_INNING_MOST_HIT_4N_KEY: "ID" + 284,
    ODDS_TYPE_BASEBALL_INNING_MOST_HIT_5N: 285,
    ODDS_TYPE_BASEBALL_INNING_MOST_HIT_5N_KEY: "ID" + 285,
    ODDS_TYPE_BASEBALL_INNING_MOST_HIT_6N: 286,
    ODDS_TYPE_BASEBALL_INNING_MOST_HIT_6N_KEY: "ID" + 286,
    ODDS_TYPE_BASEBALL_INNING_MOST_HIT_7N: 287,
    ODDS_TYPE_BASEBALL_INNING_MOST_HIT_7N_KEY: "ID" + 287,
    ODDS_TYPE_BASEBALL_INNING_MOST_HIT_8N: 288,
    ODDS_TYPE_BASEBALL_INNING_MOST_HIT_8N_KEY: "ID" + 288,

    //야구 이닝별 total hit
    ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_1N: 291,
    ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_1N_KEY: "ID" + 291,
    ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_2N: 292,
    ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_2N_KEY: "ID" + 292,
    ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_3N: 293,
    ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_3N_KEY: "ID" + 293,
    ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_4N: 294,
    ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_4N_KEY: "ID" + 294,
    ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_5N: 295,
    ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_5N_KEY: "ID" + 295,
    ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_6N: 296,
    ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_6N_KEY: "ID" + 296,
    ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_7N: 297,
    ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_7N_KEY: "ID" + 297,
    ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_8N: 298,
    ODDS_TYPE_BASEBALL_INNING_TOTAL_HIT_8N_KEY: "ID" + 298,


    //농구
    ODDS_TYPE_BASKETBALL_FULLTIME_RUN_LINE: 300,          //농구 Spread
    ODDS_TYPE_BASKETBALL_FULLTIME_RUN_LINE_KEY: "ID" + 300,          //KEY
    ODDS_TYPE_BASKETBALL_FULLTIME_TOTAL: 301,              //농구 TOTAL
    ODDS_TYPE_BASKETBALL_FULLTIME_TOTAL_KEY: "ID" + 301,              //key
    ODDS_TYPE_BASKETBALL_FULLTIME_MONEY_LINE: 302,          //농구 MONEY_LINE
    ODDS_TYPE_BASKETBALL_FULLTIME_MONEY_LINE_KEY: "ID" + 302,          //농구 MONEY_LINE
    ODDS_TYPE_BASKETBALL_FULLTIME_MATCH_ODD_EVEN: 303,          //농구 홀짝
    ODDS_TYPE_BASKETBALL_FULLTIME_MATCH_ODD_EVEN_KEY: "ID" + 303,          //농구 홀짝
    ODDS_TYPE_BASKETBALL_FULLTIME_ALTERNATIVE_TOTAL: 304,          //Alternative  Total
    ODDS_TYPE_BASKETBALL_FULLTIME_ALTERNATIVE_TOTAL_KEY: "ID" + 304,          // Alternative  Total


    ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_1: 320,          //농구 1Half 홀짝
    ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_1_KEY: "ID" + 320,      //
    ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_1: 321,          //농구 1Half Spread 핸디
    ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_1_KEY: "ID" + 321,          //
    ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_1: 322,          //농구 1Half TOTAL
    ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_1_KEY: "ID" + 322,          //
    ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_1: 323,          //농구 1Half MONEY_LINE 승패
    ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_1_KEY: "ID" + 323,          //
    ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_1: 324,          //농구
    ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_1_KEY: "ID" + 324,          //


    ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_2: 330,          //농구 2Half 홀짝
    ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_2_KEY: "ID" + 330,          //농구 2Half 홀짝
    ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_2: 331,          //농구 1Half Spread 핸디
    ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_2_KEY: "ID" + 331,          //농구 1Half Spread 핸디
    ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_2: 332,          //농구 1Half TOTAL
    ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_2_KEY: "ID" + 332,          //농구 1Half TOTAL
    ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_2: 333,          //농구 1Half MONEY_LINE 승패
    ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_2_KEY: "ID" + 333,          //농구 1Half MONEY_LINE 승패
    ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_2: 334,          //농구 1q money line 승패
    ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_2_KEY: "ID" + 334,          //농구 1q money line 승패


    ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_1Q: 340,          //농구 1q홀짝
    ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_1Q_KEY: "ID" + 340,          //농구 1q홀짝
    ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_1Q: 341,          //농구 1q Spread 핸디
    ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_1Q_KEY: "ID" + 341,          //농구 1q Spread 핸디
    ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_1Q: 342,          //농구 1q total 오버언더
    ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_1Q_KEY: "ID" + 342,          //농구 1q total 오버언더
    ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_1Q: 343,          //농구 1q money line 승패
    ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_1Q_KEY: "ID" + 343,          //농구 1q money line 승패
    ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_1Q: 344,          //농구
    ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_1Q_KEY: "ID" + 344,
    ODDS_TYPE_BASKETBALL_HALFIME_BOTH_TEAMS_SCORE_1Q: "ID" + 345,          //농구 1q BOTH_TEAMS_SCORE
    ODDS_TYPE_BASKETBALL_HALFIME_BOTH_TEAMS_SCORE_1Q_KEY: "ID" + 345,          //농구 1q BOTH_TEAMS_SCORE


    ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_2Q: 350,          //농구 2q 홀짝
    ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_2Q_KEY: "ID" + 350,          //농구 2q 홀짝
    ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_2Q: 351,          //농구 2Q Spread 핸디
    ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_2Q_KEY: "ID" + 351,          //농구 2Q Spread 핸디
    ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_2Q: 352,          //농구 2q total 오버언더
    ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_2Q_KEY: "ID" + 352,          //농구 2q total 오버언더
    ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_2Q: 353,          //농구 2q money line 승패
    ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_2Q_KEY: "ID" + 353,          //농구 2q money line 승패
    ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_2Q: 354,          //농구 2q money line 승패
    ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_2Q_KEY: "ID" + 354,          //농구 2q money line 승패
    ODDS_TYPE_BASKETBALL_HALFIME_BOTH_TEAMS_SCORE_2Q: 355,          //농구 1q BOTH_TEAMS_SCORE
    ODDS_TYPE_BASKETBALL_HALFIME_BOTH_TEAMS_SCORE_2Q_KEY: "ID" + 355,          //농구 1q BOTH_TEAMS_SCORE


    ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_3Q: 360,          //농구 3q 홀짝
    ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_3Q_KEY: "ID" + 360,          //농구 3q 홀짝
    ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_3Q: 361,          //농구 31q Spread 핸디
    ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_3Q_KEY: "ID" + 361,          //농구 31q Spread 핸디
    ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_3Q: 362,          //농구 3q total 오버언더
    ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_3Q_KEY: "ID" + 362,          //농구 3q total 오버언더
    ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_3Q: 363,          //농구 3q total 오버언더
    ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_3Q_KEY: "ID" + 363,          //농구 3q total 오버언더
    ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_3Q: 364,          //농구 3q money line 승패
    ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_3Q_KEY: "ID" + 364,          //농구 3q money line 승패
    ODDS_TYPE_BASKETBALL_HALFIME_BOTH_TEAMS_SCORE_3Q: 365,          //농구 1q BOTH_TEAMS_SCORE
    ODDS_TYPE_BASKETBALL_HALFIME_BOTH_TEAMS_SCORE_3Q_KEY: "ID" + 365,          //농구 1q BOTH_TEAMS_SCORE


    ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_4Q: 370,          //농구 4q 홀짝
    ODDS_TYPE_BASKETBALL_HALFIME_ODD_EVEN_4Q_KEY: "ID" + 370,          //농구 4q 홀짝
    ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_4Q: 371,          //농구 4q Spread 핸디
    ODDS_TYPE_BASKETBALL_HALFIME_SPREAD_4Q_KEY: "ID" + 371,          //농구 4q Spread 핸디
    ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_4Q: 372,          //농구 4q total 오버언더
    ODDS_TYPE_BASKETBALL_HALFIME_TOTAL_4Q_KEY: "ID" + 372,          //농구 4q total 오버언더
    ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_4Q: 373,          //농구 4q money line 승패
    ODDS_TYPE_BASKETBALL_HALFIME_MONEY_LINE_4Q_KEY: "ID" + 373,          //농구 4q money line 승패
    ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_4Q: 374,          //농구 4q money line 승패
    ODDS_TYPE_BASKETBALL_HALFIME_ALTERNATIVE_TOTAL_4Q_KEY: "ID" + 374,          //농구 4q money line 승패
    ODDS_TYPE_BASKETBALL_HALFIME_BOTH_TEAMS_SCORE_4Q: 375,          //농구 1q BOTH_TEAMS_SCORE
    ODDS_TYPE_BASKETBALL_HALFIME_BOTH_TEAMS_SCORE_4Q_KEY: "ID" + 375,          //농구 1q BOTH_TEAMS_SCORE

    //배구
    ODDS_TYPE_VOLLEYBALL_FULLTIME_WINNER: 400,          //배구  WINNER
    ODDS_TYPE_VOLLEYBALL_FULLTIME_WINNER_KEY: "ID" + 400,
    ODDS_TYPE_VOLLEYBALL_FULLTIME_HANDICAP: 401,          //배구 HANDICAP
    ODDS_TYPE_VOLLEYBALL_FULLTIME_HANDICAP_KEY: "ID" + 401,
    ODDS_TYPE_VOLLEYBALL_FULLTIME_TOTAL: 402,              //배구 TOTAL
    ODDS_TYPE_VOLLEYBALL_FULLTIME_TOTAL_KEY: "ID" + 402,
    ODDS_TYPE_VOLLEYBALL_FULLTIME_ODD_EVEN: 403,              //배구 Odd/Even
    ODDS_TYPE_VOLLEYBALL_FULLTIME_ODD_EVEN_KEY: "ID" + 403,
    ODDS_TYPE_VOLLEYBALL_FULLTIME_CORRECT_SET_SCORE: 404,              //배구 Set 스코어
    ODDS_TYPE_VOLLEYBALL_FULLTIME_CORRECT_SET_SCORE_KEY: "ID" + 404,


    ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_1S: 440,          //배구  WINNER  1S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_1S_KEY: "ID" + 440,
    ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_1S: 441,          //배구 HANDICAP  1S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_1S_KEY: "ID" + 441,
    ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_1S: 442,              //배구 TOTAL  1S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_1S_KEY: "ID" + 442,
    ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_1S: 443,              //배구 Odd/Even 1S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_1S_KEY: "ID" + 443,
    ODDS_TYPE_VOLLEYBALL_HALFTIME_CORRECT_SET_SCORE_1S: 444,              //배구 스코어 1S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_CORRECT_SET_SCORE_1S_KEY: "ID" + 444,


    ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_2S: 450,          //배구  WINNER   2S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_2S_KEY: "ID" + 450,
    ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_2S: 451,          //배구 HANDICAP 2S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_2S_KEY: "ID" + 451,
    ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_2S: 452,              //배구 TOTAL 2S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_2S_KEY: "ID" + 452,
    ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_2S: 453,              //배구 Odd/Even 2S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_2S_KEY: "ID" + 453,
    ODDS_TYPE_VOLLEYBALL_HALFTIME_CORRECT_SET_SCORE_2S: 454,              //배구 스코어 2S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_CORRECT_SET_SCORE_2S_KEY: "ID" + 454,


    ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_3S: 460,          //배구  WINNER    3S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_3S_KEY: "ID" + 460,          //
    ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_3S: 461,          //배구 HANDICAP 3S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_3S_KEY: "ID" + 461,          //
    ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_3S: 462,              //배구 TOTAL   3S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_3S_KEY: "ID" + 462,              //
    ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_3S: 463,              //배구 Odd/Even 3S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_3S_KEY: "ID" + 463,              //
    ODDS_TYPE_VOLLEYBALL_HALFTIME_CORRECT_SET_SCORE_3S: 464,              //배구 스코어 3S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_CORRECT_SET_SCORE_3S_KEY: "ID" + 464,              //


    ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_4S: 470,          //배구  WINNER    4S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_4S_KEY: "ID" + 470,          //
    ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_4S: 471,          //배구 HANDICAP 4S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_4S_KEY: "ID" + 471,          //배
    ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_4S: 472,              //배구 TOTAL   4S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_4S_KEY: "ID" + 472,              //
    ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_4S: 473,              //배구 Odd/Even 4S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_4S_KEY: "ID" + 473,              //
    ODDS_TYPE_VOLLEYBALL_HALFTIME_CORRECT_SET_SCORE_4S: 474,              //배구 스코어 4S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_CORRECT_SET_SCORE_4S_KEY: "ID" + 474,


    ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_5S: 480,          //배구  WINNER    5S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_WINNER_5S_KEY: "ID" + 480,          //
    ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_5S: 481,          //배구 HANDICAP 5S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_HANDICAP_5S_KEY: "ID" + 481,          //
    ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_5S: 482,              //배구 TOTAL   5S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_TOTAL_5S_KEY: "ID" + 482,              //
    ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_5S: 483,              //배구 Odd/Even 5S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_ODD_EVEN_5S_KEY: "ID" + 483,              //
    ODDS_TYPE_VOLLEYBALL_HALFTIME_CORRECT_SET_SCORE_5S: 484,              //배구 스코어 5S
    ODDS_TYPE_VOLLEYBALL_HALFTIME_CORRECT_SET_SCORE_5S_KEY: "ID" + 484,              //

    //하키
    ODDS_TYPE_HOCKEY_FULLTIME_MONEY_LINE: 500,             //하키  WINNER
    ODDS_TYPE_HOCKEY_FULLTIME_MONEY_LINE_KEY: "ID" + 500,             //하키  WINNER
    ODDS_TYPE_HOCKEY_FULLTIME_LINE_HANDICAP: 501,          //하키 HANDICAP 라인
    ODDS_TYPE_HOCKEY_FULLTIME_LINE_HANDICAP_KEY: "ID" + 501,          //하키 HANDICAP 라인
    ODDS_TYPE_HOCKEY_FULLTIME_TOTAL: 502,                  //하키 TOTAL
    ODDS_TYPE_HOCKEY_FULLTIME_TOTAL_KEY: "ID" + 502,                  //하키 TOTAL
    ODDS_TYPE_HOCKEY_FULLTIME_ALTERNATIVE_PUCK_LINE: 503,  //하키 ALTERNATIVE PUCK LINES
    ODDS_TYPE_HOCKEY_FULLTIME_ALTERNATIVE_PUCK_LINE_KEY: "ID" + 503,  //하키 ALTERNATIVE PUCK LINES
    ODDS_TYPE_HOCKEY_FULLTIME_ALTERNATIVE_GAME_TOTAL: 504, //하키 ALTERNATIVE_GAME_TOTAL
    ODDS_TYPE_HOCKEY_FULLTIME_ALTERNATIVE_GAME_TOTAL_KEY: "ID" + 504, //하키 ALTERNATIVE_GAME_TOTAL
    ODDS_TYPE_HOCKEY_FULLTIME_ODD_EVEN: 505,               //하키 Odd/Even
    ODDS_TYPE_HOCKEY_FULLTIME_ODD_EVEN_KEY: "ID" + 505,               //하키 Odd/Even
    //하키 1P
    ODDS_TYPE_HOCKEY_HALFTIME_WINNER_1P: 540,               //하키 승무패
    ODDS_TYPE_HOCKEY_HALFTIME_WINNER_1P_KEY: "ID" + 540,               //하키 승무패
    ODDS_TYPE_HOCKEY_HALFTIME_LINE_HANDICAP_1P: 541,        //하키 HANDICAP 라인
    ODDS_TYPE_HOCKEY_HALFTIME_LINE_HANDICAP_1P_KEY: "ID" + 541,        //하키 HANDICAP 라인
    ODDS_TYPE_HOCKEY_HALFTIME_TOTAL_1P: 542,               //하키 TOTAL
    ODDS_TYPE_HOCKEY_HALFTIME_TOTAL_1P_KEY: "ID" + 542,               //하키 TOTAL
    //하키 2P
    ODDS_TYPE_HOCKEY_HALFTIME_WINNER_2P: 560,                        //하키 승무패
    ODDS_TYPE_HOCKEY_HALFTIME_WINNER_2P_KEY: "ID" + 560,                        //하키 승무패
    ODDS_TYPE_HOCKEY_HALFTIME_LINE_HANDICAP_2P: 561,            //하키 HANDICAP 라인
    ODDS_TYPE_HOCKEY_HALFTIME_LINE_HANDICAP_2P_KEY: "ID" + 561,            //하키 HANDICAP 라인
    ODDS_TYPE_HOCKEY_HALFTIME_TOTAL_2P: 562,                        //하키 TOTAL
    ODDS_TYPE_HOCKEY_HALFTIME_TOTAL_2P_KEY: "ID" + 562,                        //하키 TOTAL
    //하키 3P
    ODDS_TYPE_HOCKEY_HALFTIME_WINNER_3P: 580,                        //하키 승무패
    ODDS_TYPE_HOCKEY_HALFTIME_WINNER_3P_KEY: "ID" + 580,                        //하키 승무패
    ODDS_TYPE_HOCKEY_HALFTIME_LINE_HANDICAP_3P: 581,            //하키 HANDICAP 라인
    ODDS_TYPE_HOCKEY_HALFTIME_LINE_HANDICAP_3P_KEY: "ID" + 581,            //하키 HANDICAP 라인
    ODDS_TYPE_HOCKEY_HALFTIME_TOTAL_3P: 582,                        //하키 TOTAL
    ODDS_TYPE_HOCKEY_HALFTIME_TOTAL_3P_KEY: "ID" + 582,                        //하키 TOTAL

}
