<template>
    <div class="loading">
        <div class="loading_mask">
            <div class="reverse-cont">
                <div class="reverse-spinner"></div>
                <div class="timebox">
                    {{t}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import sportsConst from "../common/sportsConst";

    export default {
        name: "LoadingInplayComp",
        data() {
            return {
                t: 10,
            }
        },
        methods: {
            downTime() {
                if (this.t === 0) {
                    this.t = 10;
                }
                this.t = this.t - 1;
            }
        },
        mounted() {
            this.t = 10;
            this.downTime()
            setInterval(() => {
                this.downTime()
            }, 1000)
        }
    }
</script>

<style scoped>
    @import url("../assets/css/loading.css");
</style>