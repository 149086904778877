<template>

    <div class="sample" v-if="$store.state.sampleserver==1">
        샘플서버 입니다. 절대 입금하지 마세요!!!
    </div>
</template>

<script>
    export default {
        name: "WarningSample"
    }
</script>

<style scoped>
    .sample {
        position: fixed;
        top: 15%;
        width: 100%;
        text-align: center;
        font-weight: bold;
        color: gold;
        padding: 10px 0;
        animation: sansan 1500ms infinite;
        -webkit-animation: sansan 1500ms infinite;
    }

    @keyframes sansan {
        from {
            opacity: 1.0;
            font-size: 16px;
        }
        70% {
            opacity: 0.93;
            font-size: 15px;
        }
        50% {
            opacity: 0.76;
            font-size: 14px;
        }
        to {
            opacity: 1.0;
            font-size: 12px;
        }
    }

    @-webkit-keyframes sansan {
        from {
            opacity: 1.0;
        }
        50% {
            opacity: 0.4;
        }
        to {
            opacity: 1.0;
        }
    }
</style>