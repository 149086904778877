import {getUnCheckedMessageCount, getUserInfo} from '../network/userRequest'
import {getCommonConfig} from "../network/configRequest";

import {
  RECEIVE_USER_INFO,//회원정보
  RECEIVE_COMMON_CONFIG,//설정
  RECEIVE_UNCHEKCED_MESSAGE_COUNT,//스포츠경기
} from './mutation-types'

export default {

  actionUserInfo({commit, state}) {
    getUserInfo().then(res => {
      const userInfo = res.data.data
      commit(RECEIVE_USER_INFO, {userInfo});
    }, err => {
      console.log(err)
    })
  },
  actionUnCheckedMessageCount({commit,state}){
    getUnCheckedMessageCount().then(res=>{
      if(res.data.success){
        let count = res.data.data
        commit(RECEIVE_UNCHEKCED_MESSAGE_COUNT, {count});
      } else {
        commit(RECEIVE_UNCHEKCED_MESSAGE_COUNT, 0);
      }

    }, err => {
      console.log(err)
    })
  },
  actionCommonConfig({commit,state}){
    getCommonConfig().then(res=>{
      const commonConfig = res.data.data
      commit(RECEIVE_COMMON_CONFIG, {commonConfig});
    }, err => {
      console.log(err)
    })
  }
}