<template>
  <div class="mobile_userinfo_panel" :class="{'activeMobileUserInfo':isShowTopUserInfo}">
    <div class="close" @click="closeMobileTopUserInfo()">
      <i class="fa fa-sign-out" aria-hidden="true"></i>닫기
    </div>
    <user-info-comp></user-info-comp>
  </div>
</template>

<script>
  import UserInfoComp from "../UserInfoComp";

  export default {
    name: "MobileTopUserInfo",
    components: {UserInfoComp},
    data() {
      return {
        isShowTopUserInfo: false
      }
    },
    methods:{
      closeMobileTopUserInfo(){
        this.isShowTopUserInfo = false
      }
    },
    created() {
      this.$bus.$on('mobileTopUserInfoActive', () => {
        this.isShowTopUserInfo = true
      })
    }
  }
</script>

<style scoped>

</style>