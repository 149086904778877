let baseAgentUrl = '';
let webSokcetUrl = '';

function getProductionUrl() {
    let prot = window.location.protocol
    let domain = window.location.host
    return prot + '//' + domain
}

function getProductWebsocketUrl() {
    let domainOrigin = window.location.host
    return 'ws://' + domainOrigin + '/ws/yewresult-0.0.1-SNAPSHOT/sportswebsocket'
}

switch (process.env.NODE_ENV) {
    case 'development':
        baseAgentUrl = 'http://localhost:7070/agent/'
        webSokcetUrl = 'ws://rod-777.com/ws/yewresult-0.0.1-SNAPSHOT/sportswebsocket'
        break
    case 'production':

        baseAgentUrl = getProductionUrl() + '/agent/'
        webSokcetUrl = getProductWebsocketUrl()
        break
}

export const BASE_AGENT_URL = baseAgentUrl
export const WEBSOCKET_URL = webSokcetUrl
export const TIMEOUT = 20000