
export default {
  getUserInfo(state){
    return state.userInfo;
  },
  getSportsCartItems(state){
    return state.sportsCartItems
  },
  getInplayCartItems(state){
    return state.inplayCartItems
  },
  getRankConfig(state){
    return state.commonConfig.rankConfig
  },
  getBonusConfig(state){
    return state.commonConfig.bonusConfig
  },
  getCrossRuleConfig(state){
    return state.commonConfig.crossRuleConfig
  },
  getSelectedGameType(state){
    return state.selectedGameType
  },
  getSelectedLeagueList(state){
    return state.selectedLeagueList
  },
  getUnCheckedMessageCount(state){
    return state.uncheckedMessageCount
  },
  getDisplayPostion(state){
    return state.displayPostion
  }

}