export const RECEIVE_SHOW_LOADING = "receiveShowLoading"
export const RECEIVE_HIDE_LOADING = "receiveHideLoading"
export const RECEIVE_SHOW_LOADING_INPLAY = "receiveShowLoadingInplay"
export const RECEIVE_HIDE_LOADING_INPLAY = "receiveHideLoadingInplay"
export const RECEIVE_USER_INFO  = "receiveUserInfo"
export const RECEIVE_UNCHEKCED_MESSAGE_COUNT  = "receiveUncheckedMessageCount"
export const RECEIVE_SPORTS_LIVE  = "receiveSportsLive"
export const RECEIVE_SPORTS_ORIGINAL = "receiveSportsOriginal"
export const RECEIVE_MINIGAMES  = "receiveMinigames"
export const RECEIVE_IS_SHOW_TOPBAR = "receiveIsShowTopbar"
export const RECEIVE_COMMON_CONFIG = "receiveCommonConfig"
export const RECEIVE_SPORTS_CART_ITEM = "receiveSportsCartItem"
export const RECEIVE_SPORTS_CART_ITEM_DEL = "receiveSportsCartItemDel"
export const RECEIVE_SPORTS_CART_ITEM_DEL_ALL = "receiveSportsCartItemDelAll"
export const RECEIVE_INPLAY_CART_ITEM = "receiveInplayCartItem"
export const RECEIVE_INPLAY_CART_ITEM_DEL = "receiveInplayCartItemDel"
export const RECEIVE_INPLAY_CART_ITEM_DEL_ALL = "receiveInplayCartItemDelAll"
export const RECEIVE_CHECKED_GAME_TYPE = "receiveCheckedGameType"
export const RECEIVE_CHECKED_LEAUGE = "receiveCheckedLeague"
export const RECEIVE_CHECKED_LEAUGE_DEL = "receiveCheckedLeagueDel"
export const RECEIVE_CHECKED_LEAUGE_DELALL = "receiveCheckedLeagueDelAll"
export const RECEIVE_DISPLAY_POSTION= "receiveDisplayPosition"
export const RECEIVE_CURRENTLEISUREGAMEID  = "receiveCurrentLeisureGameId"
export const RECEIVE_CURRENTLEISUREGAMECOMP  = "receiveCurrentLeisureGameComp"
export const INIT_USERINFO= "initUserInfo"