import http from "./axios/http";

/**
 * 진행중 경기
 */
export function getOnBetLeisureGames(kind) {
    return http.get('/leisure/game/on_bet_game?kind=' + kind + '&t=' + +new Date().getTime())
}

export function getLeisureGameAttributeConfig() {
    return http.get('/leisure/game/get_attr?t=' + new Date().getTime())
}

export function getLeisureGameKindConfig(kind) {
    return http.get('/leisure/game/get_kind?kind=' + kind + '&t=' + new Date().getTime())
}

export function getParrents(kind) {
    return http.get('/leisure/game/get_patten?kind=' + kind + '&t=' + new Date().getTime())
}


/*베팅하기*/
export function doLeisureBet(game) {
    return http.post('/leisure/bet/do_bet?t=' + new Date().getTime(), game)
}

/*로또베팅*/
export function doBetLotto(lotto) {
    return http.post('/leisure/bet/do_bet_lotto?t=' + new Date().getTime(), lotto)
}

/*베팅내역*/
export function getLeisureBetList(search, pageNum, pageSize, orderBy) {
    return http.post('/leisure/bet/bet_list?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy + '&t=' + new Date().getTime(), search)
}

/*최근베팅*/
export function getlatelyBet(kind) {
    return http.get('/leisure/bet/lately_bet?kind=' + kind + '&t=' + new Date().getTime())
}

/*삭제*/
export function deleteById(id) {
    return http.get('/leisure/bet/delete_by_id?id=' + id + '&t=' + new Date().getTime())
}

/*삭제*/
export function deleteByIds(ids) {
    return http.post('/leisure/bet/delete_by_ids', {idsString: ids})
}

/*삭제*/
export function deleteAllLeisureBet() {
    return http.get('/leisure/bet/del_all?t=' + new Date().getTime())
}


/*취소*/
export function cancelById(id) {
    return http.get('/leisure/bet/cancel_by_id?id=' + id + '&t=' + new Date().getMilliseconds())
}


/*경기결과*/
export function getLeisureGameReulst(kind, pageNum, pageSize, orderBy) {
    return http.get('/leisure/game/leisure_game_result?kind=' + kind + '&pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy + '&t=' + new Date().getTime())
}

export function getBet365SportsReulst(kind, gametype) {
    return http.get('/leisure/game/get_betsports_result?kind=' + kind + '&gametype=' + gametype + '&t=' + new Date().getTime())
}

export function getDragontigerBedang() {
    return http.get('/leisure/game/get_dragontiger_bedang?t=' + new Date().getTime())
}

export function getLotusToken(code) {
    return http.get('/leisure/game/get_lotus_token?code=' + code + '&t=' + new Date().getTime())
}


/*룰렛*/

export function getRulletSetting(type) {
    return http.get('/rullet/get_rullet_setting?type=' + type + '&t=' + new Date().getTime())
}

export function runRullet(type) {
    return http.get('/rullet/run_rullet?type=' + type + '&t=' + new Date().getTime())
}


export function openCoupon(type) {
    return http.get('/coupon/open?type=' + type + '&t=' + new Date().getTime())
}

export function getCouponConfig() {
    return http.get('/coupon/getconfig?t=' + new Date().getTime())
}

export function getCouponHistory() {
    return http.get('/coupon/get_coupon_history?t=' + new Date().getTime())
}

/*export function openCoupon(type) {
  return http.get('/couponbyrecharge/open?type=' + type + '&t=' + new Date().getTime())
}
export function getCouponConfig() {
  return http.get('/couponbyrecharge/getconfig?t=' + new Date().getTime())
}
export function getCouponHistory() {
  return http.get('/couponbyrecharge/get_coupon_history?t=' + new Date().getTime())
}*/


/*ThirdPartgame Result*/
export function getThirdPartGameResult(kind, pageNum, pageSize, orderBy) {
    return http.get('/casino/get_tgame365_bet_lit?kind=' + kind + '&pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy + '&t=' + new Date().getTime())
}

/*T365삭제*/
export function deleteT365BetById(id) {
    return http.get('/casino/delete_tgame365_betinfo_byid?id=' + id + '&t=' + new Date().getTime())
}

export function deleteT365BetAll() {
    return http.get('/casino/delete_tgame365_betinfo_all')
}

