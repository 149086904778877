<template>
  <div class="loading">
    <div class="loading_mask">
      <div class="reverse-cont">
        <div class="reverse-spinner"></div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: "LoadingComp"
  }
</script>

<style scoped>
  @import url("../assets/css/loading.css");
</style>