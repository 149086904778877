<template>
    <div class="toast" v-show="visible">
        {{ message }}
    </div>
</template>

<script>
    export default {
        name: 'toast',
        data () {
            return {
                message: '', //
                duration: 3000, //
                closed: false, //
                timer: null, //
                visible: false //
            }
        },
        mounted () {
            this.startTimer()
        },
        watch: {
            closed (newVal) {
                if (newVal) {
                    this.visible = false
                    this.destroyElement()
                }
            }
        },
        methods: {
            destroyElement () {
                this.$destroy()
                this.$el.parentNode.removeChild(this.$el)
            },
            startTimer () {
                this.timer = setTimeout(() => {
                    if (!this.closed) {
                        this.closed = true
                        clearTimeout(this.timer)
                    }
                }, this.duration)
            }
        }
    }
</script>

<style scoped>
    .toast {
        position: fixed;
        min-width: 50px;
        max-width: 340px;
        text-align: center;
        padding: 20px;
        border: 4px solid #f8f1e3;
        border-radius: 4px;
        background-color: #ffffff;
        opacity: 0.98;
        color: #000000;
        letter-spacing: 3px;
        font-weight: bold;
        font-size: 16px;
        top: calc(35% - 20px);
        left: 0;
        right: 0;
        margin:auto;
        box-shadow: inset 0 2px 13px rgba(27, 26, 19, 0.92);
    }
</style>