export const comma = function (val) {
  let num = String(val).trim();
  let result = parseInt(val);

  if (num.length >= 4) {

    let re = /^$|,/g;

    num = num.replace(re, "");

    let fl = ""

    if (isNaN(num)) {
      return val
    }

    if (num === 0) return num;

    if (num < 0) {
      num = num * (-1);
      fl = "-";

    } else {
      num = num * 1 //처음 입력값이 0부터 시작할때 이것을 제거한다.
    }

    num = String(num)

    let temp = "";

    let co = 3;

    let num_len = num.length;

    while (num_len > 0) {

      num_len = num_len - co;

      if (num_len < 0) {
        co = num_len + co;
        num_len = 0;
      }
      temp = "," + num.substr(num_len, co) + temp;
    }
    result = fl + temp.substr(1);

  }
  return result;
}

export const replace = function (content, patten, rep2string) {
  let reg = new RegExp(patten, "g");
  return (content + '').replace(reg, rep2string);
}
